<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Accueil</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container" class="p-5">
        <ion-button expand="block" :disabled="loading" @click="newGame">Créer une partie</ion-button>
        OU Rejoindre une partie<br>
        <ion-input v-model="code" placeholder="Code ..."></ion-input>
        <ion-button @click="goToGame">Y aller</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  data: () => ({
    loading: false,
    code: ``,
  }),
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonInput,
  },
  methods: {
    goToGame() {
      this.$router.push(`/game/${this.code}`);
    },
    newGame() {
      this.loading = true;
      this.$socket.client.emit(`newGame`);
    },
  },
  sockets: {
    newGame(id: any) {
      this.$router.push(`/game/${id}`);
      this.code = ``;
      this.loading = false;
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
