
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  data: () => ({
    loading: false,
    code: ``,
  }),
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonInput,
  },
  methods: {
    goToGame() {
      this.$router.push(`/game/${this.code}`);
    },
    newGame() {
      this.loading = true;
      this.$socket.client.emit(`newGame`);
    },
  },
  sockets: {
    newGame(id: any) {
      this.$router.push(`/game/${id}`);
      this.code = ``;
      this.loading = false;
    },
  },
});
