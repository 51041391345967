
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonModal, IonInput, IonButton, IonSelect, IonSelectOption, IonButtons, IonIcon, toastController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { find, cloneDeep, map, sumBy, toNumber, remove } from 'lodash';
import { addIcons } from 'ionicons';
import { homeOutline } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonModal,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonButtons,
    IonIcon,
  },
  data: () => ({
    playerId: null,
    players: [] as any,
    error: false,
    isOpened: false,
    name: ``,
    rows: [
      {
        order: 1,
        bonus: 35,
        minForBonus: 63,
        children: [
          {
            key: `1`,
            type: `number`,
          },
          {
            key: `2`,
            type: `number`,
          },
          {
            key: `3`,
            type: `number`,
          },
          {
            key: `4`,
            type: `number`,
          },
          {
            key: `5`,
            type: `number`,
          },
          {
            key: `6`,
            type: `number`,
          },
        ]
      },
      {
        order: 2,
        bonus: 0,
        minForBonus: 0,
        children: [
          {
            key: `BR`,
            type: `custom`,
          },
          {
            key: `F`,
            type: `auto`,
            value: 30,
          },
          {
            key: `CA`,
            type: `auto`,
            value: 40,
          },
          {
            key: `PS`,
            type: `auto`,
            value: 25,
          },
          {
            key: `GS`,
            type: `auto`,
            value: 35,
          },
          {
            key: `CH`,
            type: `custom`,
          },
          {
            key: `Y`,
            type: `auto`,
            value: 50,
          },
        ],
      },
      {
        order: 3,
        bonus: 0,
        minForBonus: 0,
        children: [
          {
            key: `+`,
            type: `custom`,
          },
          {
            key: `-`,
            type: `custom`,
          },
        ],
      },
    ],
  }),
  mounted() {
    addIcons({
      'home-outline': homeOutline,
    });
    this.$socket.client.emit(`join`, this.$route.params.id, (error: any) => {
      if (error.notFound) {
        this.error = true;
      }
    });
  },
  computed: {
    gameID(): any {
      return this.$route.params.id;
    },
    canPlay() {
      return (player: any, score: any): any => player.id === this.playerId;
    },
    currentPlayer(): any {
      return find(this.players as any, { id: this.playerId });
    },
  },
  methods: {
    changeName() {
      this.isOpened = true;
    },
    getGrandTotal(player: any) {
      return sumBy(this.rows, (r: any) => this.getTotalForPart(r.order, player, true));
    },
    hasBonus(order: number, player: any): boolean {
      const part = find(this.rows, { order }) || { bonus: 0, minForBonus: 0 };
      const playerScore = this.getBaseScoreForPart(order, player);

      return playerScore >= part.minForBonus;
    },
    getTotalForPart(order: number, player: any, bonus: boolean) {
      const part = find(this.rows, { order }) || { bonus: 0 };
      const playerScore = this.getBaseScoreForPart(order, player);

      if (bonus && this.hasBonus(order, player)) {
        return playerScore + part.bonus;
      }
      return playerScore;
    },
    getBaseScoreForPart(order: number, player: any) {
      const part = find(this.rows, { order });
      if (!part) return 0;
      const values = map(part.children, `key`);
      let playerScore = sumBy(player.scores, (s: any) => {
        if (values.includes(s.key) && s.key !== `-`) {
          return toNumber(s.value) ||0;
        }
        return 0;
      });

      const minus = find(player.scores, { key: `-` });
      if (find(part.children, { key: `-` })) {
        playerScore -= (minus || {}).value ||0;
      }

      return playerScore;
    },
    setGlobalScore(score: any) {
      this.$socket.client.emit(`setScore`, this.gameID, score);
    },
    saveScore(score: any) {
      localStorage.setItem(`score${this.gameID}`, JSON.stringify(score));
    },
    changeValueFor(event: any) {
      const value = event.detail.value;
      const key = event.target.dataset.key;
      this.setValue(key, value);
    },
    setAutoValue(event: any) {
      const target = event.target.dataset.key ? event.target : event.target.parentElement;
      const key = target.dataset.key;
      let value = target.dataset.value;
      const score = find(this.currentPlayer.scores, { key }) ||{};
      if (score.value === value && value) {
        value = 0;
      } else if (score.value === `0` && value) {
        value = null;
      }
      this.setValue(key, value);
    },
    setValue(key: any, value: any) {
      this.$socket.client.emit(`setValue`, this.gameID, key, value);
      const score = cloneDeep(this.currentPlayer.scores);
      remove(score, { key });
      score.push({
        key,
        value,
      });
      this.saveScore(score);
    },
    getPlayerScoreFor(key: any, player: any) {
      return (find(player.scores, { key }) || {}).value;
    },
    setName() {
      localStorage.setItem('name', this.name);
      this.$socket.client.emit(`setName`, this.gameID, this.name);
      this.isOpened = false;
    },
  },
  sockets: {
    async newValue({ name, key, value }) {
      if (name === this.name) return;

      const link = key === `PS` || key === `GS` || key === `CH` ? `à la` : `au`;
      const toast = await toastController.create({
        message: `${name} a joué ${value} ${link} ${key}`,
        duration: 10000,
      });

      return toast.present();
    },
    disconnect() {
      window.location.reload();
    },
    setPlayerId(playerId) {
      this.playerId = playerId;
    },
    setPlayers(players) {
      const rawPlayers = players;
      const actualPlayer = remove(rawPlayers, (p: any) => p.name === this.name)[0];
      rawPlayers.unshift(actualPlayer);
      this.players = rawPlayers.filter((p: any) => p);
    },
    setName() {
      const name = localStorage.getItem(`name`);
      let score = localStorage.getItem(`score${this.gameID}`);
      score = score ? JSON.parse(score) : null;

      if (name) {
        this.name = name;
        this.setName();
        this.setGlobalScore(score);
      } else {
        this.isOpened = true;
      }
    },
  },
});
